<template>
  <div>
    <form @submit.prevent="submit">
      <div class="row">
        <div class="flex xs12">
          <va-card :title="formTitle">
            <div slot="actions">
              <slot name="card-actions"></slot>
            </div>
            <div class="row">
              <loading-notification :show="loading || verifying" />
              <div class="flex xs12 sm4">
                <va-select
                  v-model="selectedFormType"
                  :label="$t('forms.inputs.form_type')"
                  :options="formTypes"
                  :no-options-text="$t('layout.empty')"
                  :disabled="loading"
                  text-by="name"
                  searchable
                />
              </div>
              <div class="flex xs12 sm4">
                <va-select
                  v-model="dateSince"
                  :label="$t('forms.inputs.active_since')"
                  :options="yearsSince"
                  :no-options-text="$t('layout.empty')"
                  :disabled="loading"
                  searchable
                />
              </div>
              <div class="flex xs12 sm4">
                <va-select
                  v-model="dateUntil"
                  :label="$t('forms.inputs.active_until')"
                  :options="yearsUntil"
                  :no-options-text="$t('layout.empty')"
                  :disabled="loading"
                  searchable
                />
              </div>
            </div>
          </va-card>
        </div>
      </div>

      <draggable class="row" v-model="questions" group="questions" @start="drag=true" @end="drag=false">
        <div class="flex xs12 mt-3" v-for="(question, index) in questions" :key="question.id">
          <va-card>
            <h3 slot="header" class="row">
              <span class="flex xs12 sm1" style="cursor: move;">
                <i class="fa fa-bars"></i>
              </span>
              <strong class="flex xs12 sm8">{{ $t('forms.inputs.question_name', { id: index + 1 }) }}</strong>
              <va-button
                flat
                small
                class="flex xs12 sm2"
                color="danger"
                icon="fa fa-trash"
                @click.prevent="deleteItem(question.id)"
              >
                {{ $t('tables.actions.delete') }}
              </va-button>
            </h3>
            <div class="row">
              <div class="flex xs12 sm3">
                <va-select
                  v-model="question.type"
                  :label="$t('forms.inputs.question_type')"
                  :options="fieldTypes"
                  :no-options-text="$t('layout.empty')"
                  :disabled="loading"
                  searchable
                />
              </div>
              <div class="flex xs12 sm3">
                <va-select
                  v-model="question.size"
                  :label="$t('forms.inputs.question_size')"
                  :options="fieldSizes"
                  :no-options-text="$t('layout.empty')"
                  :disabled="loading"
                  searchable
                />
              </div>
              <div class="flex xs12 sm6">
                <va-select
                  v-model="question.visible"
                  :label="$t('forms.inputs.question_condition')"
                  :options="getFieldVisibilityConditions(question)"
                  :no-options-text="$t('layout.empty')"
                  :disabled="loading"
                  text-by="label"
                  searchable
                />
              </div>
              <div class="flex xs12">
                <va-input
                  color="info"
                  v-model="question.title"
                  type="text"
                  :disabled="loading"
                  :label="$t('forms.input.question_title')"
                  :error="!question.title.length"
                  :error-messages="[$t('validator.required')]"
                >
                  <va-popover
                    :message="$t('layout.translation')"
                    v-if="currentUser.can('Translate', 'translate')"
                    placement="top"
                    slot="append"
                  >
                    <va-button
                      flat
                      small
                      type="button"
                      color="primary"
                      style="margin-right: 0;"
                      icon="entypo entypo-language"
                      @click="openTranslator(question.title)"
                    />
                  </va-popover>
                </va-input>
                <!--
                <tabs-container :tabs="tabs">
                  <template v-slot:es>
                    <va-input
                      color="info"
                      v-model="question.title"
                      type="text"
                      :disabled="loading"
                      :label="$t('forms.input.question_title')"
                      :error="!question.title.length"
                      :error-messages="[$t('validator.required')]"
                    >
                      <va-popover
                        :message="$t('layout.translation')"
                        v-if="currentUser.can('Translate', 'translate')"
                        placement="top"
                        slot="append"
                      >
                        <va-button
                          flat
                          small
                          type="button"
                          color="primary"
                          style="margin-right: 0;"
                          icon="entypo entypo-language"
                          @click="translateMissing(question)"
                        />
                      </va-popover>
                    </va-input>
                  </template>
                  <template v-slot:en>
                    <va-input
                      color="info"
                      v-model="question.title_en"
                      type="text"
                      :disabled="loading"
                      :label="$t('forms.input.question_title')"
                      :error="!question.title_en.length"
                      :error-messages="[$t('validator.required')]"
                    >
                      <va-popover
                        :message="$t('layout.translation')"
                        v-if="currentUser.can('Translate', 'translate')"
                        placement="top"
                        slot="append"
                      >
                        <va-button
                          flat
                          small
                          type="button"
                          color="primary"
                          style="margin-right: 0;"
                          icon="entypo entypo-language"
                          @click="translateMissing(question)"
                        />
                      </va-popover>
                    </va-input>
                  </template>
                  <template v-slot:pt>
                    <va-input
                      color="info"
                      v-model="question.title_pt"
                      type="text"
                      :disabled="loading"
                      :label="$t('forms.input.question_title')"
                      :error="!question.title_pt.length"
                      :error-messages="[$t('validator.required')]"
                    >
                      <va-popover
                        :message="$t('layout.translation')"
                        v-if="currentUser.can('Translate', 'translate')"
                        placement="top"
                        slot="append"
                      >
                        <va-button
                          flat
                          small
                          type="button"
                          color="primary"
                          style="margin-right: 0;"
                          icon="entypo entypo-language"
                          @click="translateMissing(question)"
                        />
                      </va-popover>
                    </va-input>
                  </template>
                </tabs-container>
                -->
              </div>
            </div>
          </va-card>
        </div>
      </draggable>

      <div class="row">
        <div class="flex xs12">
          <va-button
            type="button"
            color="success"
            :disabled="loading || verifying"
            @click.prevent="addQuestion"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-plus"
            >
              {{ $t('forms.inputs.add_field') }}
            </text-loading>
          </va-button>
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-collapse withBackground>
            <span slot="header">{{ $t('layout.preview') }}</span>
            <template slot="body">
              <form-builder :loading="loading" :questions="questions" />
            </template>
          </va-collapse>
        </div>
      </div>

      <div class="row">
        <div class="flex xs12">
          <va-button
            color="primary"
            :disabled="loading || verifying"
          >
            <text-loading
              :loading="loading"
              icon="fa fa-save"
            >
              {{ $t('layout.form.save') }}
            </text-loading>
          </va-button>
        </div>
      </div>
    </form>
    <va-modal
      ref="translatorModal"
      v-model="translatorModal"
      :max-width="'100vw'"
      :title="$t('layout.modals.translations')"
      :hide-default-actions="true"
      :fullscreen="true"
      @cancel="closeTranslatorModal"
    >
      <translator-modal :edit="true" :path="currentPath" @saved="closeTranslatorModal"/>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Draggable from 'vuedraggable'
const TranslatorModal = () => import(/* webpackPrefetch: true */ '@/components/extras/TranslatorModal')
const FormBuilder = () => import(/* webpackPrefetch: true */ '@/components/extras/FormBuilder')

export default {
  name: 'forms-form',
  components: {
    Draggable,
    FormBuilder,
    TranslatorModal,
  },
  props: {
    loading: {
      type: Boolean,
      default: function () { return false },
    },
    form: {
      type: Object,
      required: false,
      default: function () {
        return {
          id: 0,
        }
      },
    },
    formTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    formTypes () {
      return [
        { id: 0, name: this.$t('forms.inputs.types.team') },
        { id: 1, name: this.$t('forms.inputs.types.district') },
      ]
    },
    fieldSizes () {
      const sizes = []
      for (let i = 1; i <= 12; i++) {
        sizes.push('sm' + i)
      }
      return sizes
    },
    fieldTypes () {
      return [
        'text',
        'email',
        'textarea',
        'number',
        'yes/no',
      ]
    },
    tabs () {
      const t = [
        { title: this.$t('layout.translations.original'), name: 'es' },
        { title: this.$t('layout.translations.english'), name: 'en' },
        { title: this.$t('layout.translations.portuguese'), name: 'pt' },
      ]
      return t
    },
    conditionalVisibility () {
      const conditions = [
        { id: 0, key: -1, label: this.$t('forms.visibility.always') },
      ]
      const conditionants = this.questions.filter(q => q.type === 'yes/no')
      for (const c of conditionants) {
        const i = this.questions.findIndex(x => x.id === c.id)
        conditions.push(
          { id: conditions.length, key: c.id, label: this.$t('forms.visibility.question_yes', { id: i + 1 }), value: true },
          { id: conditions.length + 1, key: c.id, label: this.$t('forms.visibility.question_no', { id: i + 1 }), value: false },
        )
      }
      return conditions
    },
  },
  data () {
    return {
      currentPath: '',
      translatorModal: false,
      verifying: false,
      isError: {
        assigned: false,
      },
      yearsSince: [],
      yearsUntil: [],
      selectedFormType: '',
      dateSince: '',
      dateUntil: '',
      questions: [],
    }
  },
  watch: {
    form (val) {
      this.setForm(val)
    },
    async dateSince (val) {
      await this.$nextTick()
      this.yearsUntil = this.getYearsUntil().slice(0)
    },
    async dateUntil (val) {
      await this.$nextTick()
      this.yearsSince = this.getYearsSince().slice(0)
    },
  },
  created () {
    this.initialData()
    this.yearsSince = this.getYearsSince().slice(0)
    this.yearsUntil = this.getYearsUntil().slice(0)
  },
  methods: {
    setForm (val) {
      const definition = JSON.parse(val.definition)
      if (definition.length) {
        this.questions = this.fixData(definition)
      }
      if (Number.isInteger(val.type)) {
        const type = this.formTypes.find(t => t.id === val.type)
        if (type) {
          this.selectedFormType = type
        }
      }
      if (val.valid_since) {
        const date = val.valid_since.split('-')
        this.dateSince = date[0]
      }
      if (val.valid_until) {
        const date = val.valid_until.split('-')
        this.dateUntil = date[0]
      }
    },
    initialData () {
      if (this.form) {
        this.setForm(this.form)
      }
    },
    openTranslator (title) {
      this.currentPath = title
      this.translatorModal = true
    },
    closeTranslatorModal () {
      this.translatorModal = false
    },
    fixData (definitions) {
      for (const d of definitions) {
        if (!d.visible) {
          d.visible = { id: 0, key: -1, label: this.$t('forms.visibility.always') }
        }
      }
      return definitions.slice(0)
    },
    async translateMissing (question) {
      let from = null
      let text = null

      const esText = question.title
      const enText = question.title_en
      const ptText = question.title_pt
      if (!this.checkEmpty(esText)) {
        from = 'es'
        text = esText
      } else if (!this.checkEmpty(ptText)) {
        from = 'pt'
        text = ptText
      } else if (!this.checkEmpty(enText)) {
        from = 'en'
        text = enText
      } else {
        // console.log('All names empty')
        return
      }

      if (this.checkEmpty(esText)) {
        this.translate(from, 'es', text, question)
      }
      if (this.checkEmpty(ptText)) {
        this.translate(from, 'pt', text, question)
      }
      if (this.checkEmpty(enText)) {
        this.translate(from, 'en', text, question)
      }
    },
    async translate (from, lang, text, question) {
      const body = {
        text: text,
        from: from,
        to: lang,
      }
      let response = false
      try {
        response = await this.$http.post('/translate', body)
      } catch (err) {
        this.loadingLang[lang] = false
        this.showToast(this.$t('notifications.translate.error'), {
          icon: 'fa-times',
          position: 'top-right',
        })
        return
      }

      const translation = response.data.data || []
      if (translation.length > 0) {
        const tag = lang !== 'es' ? `title_${lang}` : 'title'
        const value = translation[0].translations[0].text
        question[tag] = value
      }
    },
    checkEmpty (field) {
      return field === undefined || field === null || field === ''
    },
    deleteItem (id) {
      const element = this.questions.findIndex(e => e.id === id)
      if (element !== -1) {
        for (const q of this.questions) {
          if (q.visible && q.visible.key === id) {
            q.visible = this.conditionalVisibility[0]
          }
        }
        this.questions.splice(element, 1)
      }
    },
    showErrorMessage (message) {
      this.showToast(message, {
        icon: 'fa-times',
        position: 'top-right',
        duration: 2500,
        fullWidth: false,
      })
    },
    getYearsSince () {
      let currentYear = new Date().getFullYear()
      let maxYear = currentYear + 10
      if (this.dateUntil !== '') {
        currentYear = Number(this.dateUntil)
        maxYear = currentYear - 1
      }
      const minYear = currentYear - 10

      const years = []
      for (let i = minYear; i < maxYear; i++) {
        years.push(i.toString())
      }
      return years
    },
    getYearsUntil () {
      let currentYear = new Date().getFullYear()
      let minYear = currentYear - 10
      if (this.dateSince !== '') {
        currentYear = Number(this.dateSince)
        minYear = currentYear + 1
      }
      const maxYear = currentYear + 10

      const years = []
      for (let i = minYear; i < maxYear; i++) {
        years.push(i.toString())
      }
      return years
    },
    getFieldVisibilityConditions (question) {
      const conditions = this.conditionalVisibility.filter(x => x.key !== question.id)
      if (question.visible) {
        question.visible = conditions.find(x => x.id === question.visible.id)
      }
      return conditions
    },
    addQuestion () {
      const ids = this.questions.map(x => x.id)
      let id = Math.max(...ids) + 1
      if (Number.isNaN(id)) {
        id = this.questions.length + 1
      }
      const question = {
        id: id,
        type: 'text',
        size: 'sm12',
        visible: this.conditionalVisibility[0],
        title: '',
        title_en: '',
        title_pt: '',
      }
      this.questions.push(question)
    },
    async submit () {
      const formDefinition = {}
      if (this.selectedFormType) {
        formDefinition.type = this.selectedFormType.id
      } else {
        this.showErrorMessage(this.$t('forms.errors.type_empty'))
        return
      }
      if (this.dateSince !== '') {
        formDefinition.valid_since = `${this.dateSince}-09-01`
      } else {
        this.showErrorMessage(this.$t('forms.errors.date_since_empty'))
        return
      }
      if (this.dateUntil !== '') {
        formDefinition.valid_until = `${this.dateUntil}-08-30`
      } else {
        this.showErrorMessage(this.$t('forms.errors.date_until_empty'))
        return
      }

      if (this.questions.length > 0) {
        for (const q of this.questions) {
          q.value = ''
        }
        formDefinition.definition = JSON.stringify(this.questions)
      } else {
        this.showErrorMessage(this.$t('forms.errors.questions_empty'))
        return
      }

      this.$emit('submit', formDefinition)
    },
  },
}
</script>

<style lang="scss">
.questionary {
  background-color: lightblue;
  border: var(--brand-gray) solid 1px;
  border-radius: 10px;
}
</style>
